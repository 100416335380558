import { ThunkDispatch } from 'redux-thunk';

import actionTypes from 'store/action-types';
import AuthService from 'shared/services/auth.service';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/constants';
import Utility from 'shared/util/utility';
import { Action } from 'shared/interface';
import { LoginResponse, UserLoginData } from '../interface/login.interface';

const login = (data: UserLoginData) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(Utility.createAction(actionTypes.AUTH_INIT));
		HttpService.post(API_CONFIG.path.login, data, {}).then((response: LoginResponse) => {
			AuthService.setAuthData(response);
			dispatch(Utility.createAction(actionTypes.AUTH_SUCCESS, response));
		}).catch((e) => {
			dispatch(Utility.createAction(actionTypes.AUTH_FAIL, { message: e.message }));
		});
	};
}


const logout = () => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		AuthService.removeAuthData();
		dispatch(Utility.createAction(actionTypes.AUTH_LOGOUT_SUCCESS));
		// dispatch(Utility.createAction(actionTypes.AUTH_LOGOUT_INIT));
		// HttpService.get(API_CONFIG.path.logout).then(() => {
		// }).catch((e) => {
		// 	dispatch(Utility.createAction(actionTypes.AUTH_LOGOUT_FAIL, { message: e.message }));
		// });
	};
}


export {
	login,
	logout,
}

