import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-toastify/dist/ReactToastify.css";
import "assets/styles/datatables.min.css";

import "./assets/styles/index.scss";
import Layout from "shared/hoc/layout/component/layout.component";
import AuthService from "shared/services/auth.service";
import Spinner from "shared/components/spinner/spinner";

import Notification from "shared/components/notification/notification";

/*
 * import async components in order to lazy load the containers, which will be rendered conditionally
 * based on the permissions user is having
 */
import * as asyncComponents from "shared/hoc/asyncComponents";

const App: React.FC = () => {
	const isLoggedIn = AuthService.checkLogin();
	if (isLoggedIn) {
		return (
			<Layout>
				<Notification />
				<Suspense fallback={<Spinner />}>
					<Switch>
						<Route
							exact
							path="/"
							component={() => <Redirect to="/subscribers" />}
						/>
						<Route
							exact
							path="/subscribers"
							component={asyncComponents.Subscribers}
						/>
						<Route
							exact
							path="/newsletters"
							component={asyncComponents.PostNewsLetterList}
						/>
						<Route
							exact
							path="/newsletters/create"
							component={asyncComponents.CreateAndUpdateNewsLetter}
						/>
						<Route
							exact
							path="/newsletters/update/:id"
							component={asyncComponents.CreateAndUpdateNewsLetter}
						/>
						<Route
							exact
							path="/make-money"
							component={asyncComponents.PostMakeMoneyList}
						/>
						<Route
							exact
							path="/make-money/create"
							component={asyncComponents.CreateAndUpdateMakeMoney}
						/>
						<Route
							exact
							path="/make-money/update/:id"
							component={asyncComponents.CreateAndUpdateMakeMoney}
						/>
						<Route
							exact
							path="/save-more"
							component={asyncComponents.PostSaveMoneyList}
						/>
						<Route
							exact
							path="/save-more/create"
							component={asyncComponents.CreateAndUpdateSaveMoney}
						/>
						<Route
							exact
							path="/save-more/update/:id"
							component={asyncComponents.CreateAndUpdateSaveMoney}
						/>
						<Route
							exact
							path="/live-smarter"
							component={asyncComponents.PostLiveSmarterList}
						/>
						<Route
							exact
							path="/live-smarter/create"
							component={asyncComponents.CreateAndUpdateLiveSmarter}
						/>
						<Route
							exact
							path="/live-smarter/update/:id"
							component={asyncComponents.CreateAndUpdateLiveSmarter}
						/>
						<Route
							exact
							path="/404"
							component={asyncComponents.NotFound}
						/>
						<Redirect to="/404" />
					</Switch>
				</Suspense>
			</Layout>
		);
	}
	return (
		<>
			<Notification />
			<Suspense fallback={<Spinner />}>
				<Switch>
					<Route path="/login" component={asyncComponents.Login} />
					<Redirect to="/login" />
				</Switch>
			</Suspense>
		</>
	);
};

export default App;
