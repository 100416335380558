import actionTypes from 'store/action-types';
import { AuthState, Action } from 'shared/interface';
import AuthService from 'shared/services/auth.service';

const initialState: AuthState = {
	authData: {
		auth: AuthService.checkLogin()
	},
	closeModal: false
};

const reducer = (state: AuthState = initialState, action: Action): AuthState => {
	switch (action.type) {
		case actionTypes.AUTH_SUCCESS:
			return {
				...state,
				authData: {
					auth: true
				},
			};
		case actionTypes.NO_ACTION:
			return { ...state };
		case actionTypes.AUTH_LOGOUT_SUCCESS:
			return {
				...state,
				authData: {
					auth: false
				}
			}
		case actionTypes.CHANGE_PASSWORD_SUCCESS:
			return {
				...state,
				closeModal: true
			}
		case actionTypes.RESET_PASSWORD_SUCCESS:
			return {
				...state,
				closeModal: true
			}
		case 'RESET_MODAL':
			return {
				...state,
				closeModal: false
			}
		default:
			return state;
	}
};
export default reducer;
