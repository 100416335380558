import { combineReducers } from 'redux';
import login from 'features/login/store/login.reducer';
import loadingReducer from './loadingReducer';
import commonReducer from './commonReducer';
import subscribersReducer from 'features/subscribers/store/subscribers.reducer';
import { State, Action } from 'shared/interface';

import actionTypes from 'store/action-types';

const appReducer = combineReducers({
	auth: login,
	loading: loadingReducer,
	common: commonReducer,
	subscribers: subscribersReducer
});

const rootReducer = (state: State | undefined, action: Action): State => {
	if (action.type === actionTypes.AUTH_LOGOUT_SUCCESS || action.type === actionTypes.AUTH_LOGOUT_FAIL) {
		localStorage.removeItem('authData');
		localStorage.removeItem('userData');
		state = undefined;
	}
	return appReducer(state, action);
};

export default rootReducer;
