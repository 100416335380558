import { lazy } from "react";
import { withRouter } from "react-router";
const NotFound = withRouter(lazy(() => import("shared/components/404/404")));
const Login = withRouter(lazy(() => import("features/login/container/login")));
const Notification = withRouter(
	lazy(() => import("shared/components/notification/notification"))
);

const Subscribers = withRouter(
	lazy(() => import("features/subscribers/container/subscribers"))
);
const PostNewsLetterList = withRouter(
	lazy(() => import("features/post-newsLetter/container/posts"))
);

const CreateAndUpdateNewsLetter = withRouter(
	lazy(() => import("features/post-newsLetter/container/createAndUpdatePost"))
);

const PostSaveMoneyList = withRouter(
	lazy(() => import("features/saveMoney/container/saveMoneyPosts"))
);

const CreateAndUpdateSaveMoney = withRouter(
	lazy(() => import("features/saveMoney/container/saveMoneyCreateAndUpdatePost"))
);

const PostMakeMoneyList = withRouter(
	lazy(() => import("features/makeMoney/container/makeMoneyPosts"))
);

const CreateAndUpdateMakeMoney = withRouter(
	lazy(() => import("features/makeMoney/container/makeMoneyCreateAndUpdatePost"))
);


const PostLiveSmarterList = withRouter(
	lazy(() => import("features/liveSmarter/container/liveSmarterPosts"))
);

const CreateAndUpdateLiveSmarter = withRouter(
	lazy(() => import("features/liveSmarter/container/liveSmarterCreateAndUpdatePost"))
);
export {
	Subscribers,
	PostNewsLetterList,
	CreateAndUpdateNewsLetter,
	NotFound,
	Login,
	Notification,
	PostSaveMoneyList,
	CreateAndUpdateSaveMoney,
	PostMakeMoneyList,
	CreateAndUpdateMakeMoney,
	PostLiveSmarterList,
	CreateAndUpdateLiveSmarter,
};
