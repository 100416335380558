import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RouteComponentProps, Redirect, withRouter } from "react-router-dom";
import NavItem from "./navItem";
import { State, Action } from "shared/interface";
import Utility from "shared/util/utility";
import actionTypes from "store/action-types";

interface DispatchProps {
	renderSideNav: () => void;
}
interface UIState {
	openStatus: {
		[key: string]: boolean;
	};
}
interface OwnProps extends RouteComponentProps, State, DispatchProps { }

class SideNav extends PureComponent<OwnProps> {
	render() {
		if (!this.props.auth.authData.auth) {
			return <Redirect to="/login" />;
		}
		return (
			<nav
				className="navbar-default navbar-static-side"
				role="navigation"
			>
				<ul className="nav" id="side-menu">
					<li className="nav-header cursor-pointer">
						<h3 className="profile-element">FORTHSPARK ADMIN</h3>
						<span className="user-role-element text-muted text-xs block">
							Admin
						</span>
						<div className="logo-element">FA</div>
					</li>
					<NavItem
						renderSideNav={this.props.renderSideNav}
						menu={{ name: "subscribers", route: "/subscribers" }}
					/>
					<NavItem
						renderSideNav={this.props.renderSideNav}
						menu={{
							name: "Post Newsletter",
							route: "/newsletters",
						}}
					/>
					<NavItem
						renderSideNav={this.props.renderSideNav}
						menu={{
							name: "Make Money",
							route: "/make-money",
						}}
					/>
					<NavItem
						renderSideNav={this.props.renderSideNav}
						menu={{
							name: "Save More",
							route: "/save-more",
						}}
					/>
					<NavItem
						renderSideNav={this.props.renderSideNav}
						menu={{
							name: "Live Smarter",
							route: "/live-smarter",
						}}
					/>
				</ul>
			</nav>
		);
	}
}

const mapStateToProps = (state: State): State => state;

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): DispatchProps => ({
	/**
	 * renderSideNav - render side nav on side nav click, in order to change active tag of navigation-item
	 * when navigating from one route to another, if in any case, there isn't any action dispatch
	 * (in case if we have data in store, and we don't call API)
	 * then, store won't change, and sidenNav won't get rerendered
	 * we it's necessary to rerender sidenav, on route change, in order to update active route link
	 * renderSideNav is used to dispatch NO_ACTION, which will rerender this component
	 */
	renderSideNav: () => dispatch(Utility.createAction(actionTypes.NO_ACTION)),
});

export default connect<State, DispatchProps, {}, State>(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(SideNav));
