import queryString from "query-string";
import isEmpty from "lodash/isEmpty";
import { QueryParameters } from "../interface";

const firstLevelBreadcrumbs = [{ name: "Home", link: "/subscribers" }];

const API_CONFIG = {
	baseUrl: `${process.env.REACT_APP_BASE_URL}`,
	path: {
		login: "v1/admin/login",
		fetchSubscribers: "v1/admin/subscribers",
		syncContact: "v1/admin/subscribers/syncUnsubscribe",
		fetchExcel: "v1/admin/subscriber/excel",
		uploadAssets: "v1/admin/uploadFile",
		fetchNewsletters: "v1/admin/posts",
		createNewsletter: "v1/admin/posts/create",
		deleteNewsletter: "v1/admin/posts/delete",
		updateNewsletter: "v1/admin/posts",
		fetchMakeMoneyPosts: "v1/admin/make-money-posts",
		createMakeMoneyPost: "v1/admin/make-money-posts/create",
		deleteMakeMoneyPost: "v1/admin/make-money-posts/delete",
		updateMakeMoneyPost: "v1/admin/make-money-posts",
		fetchSaveMoneyPosts: "v1/admin/save-money-posts",
		createSaveMoneyPost: "v1/admin/save-money-posts/create",
		deleteSaveMoneyPost: "v1/admin/save-money-posts/delete",
		updateSaveMoneyPost: "v1/admin/save-money-posts",
		fetchLiveSmarterPosts: "v1/admin/live-smarter-posts",
		createLiveSmarterPost: "v1/admin/live-smarter-posts/create",
		deleteLiveSmarterPost: "v1/admin/live-smarter-posts/delete",
		updateLiveSmarterPost: "v1/admin/live-smarter-posts",
	},
};

const getUrl = (url: string, params: QueryParameters = {}): string => {
	let urlString = `${API_CONFIG.baseUrl}/api/${url}`;
	if (params && !isEmpty(params)) {
		urlString += `?${queryString.stringify(params)}`;
	}
	return urlString;
};

const userStatusDropdownOptions = [
	{ name: "All", value: "" },
	{ name: "Active", value: "1" },
	{ name: "InActive", value: "0" },
];

const pageOptions: number[] = [15, 30, 50, 100];

const PER_PAGE: number = 15;
const ENVIRONMENT =
	process.env.ENVIRONMENT === "production" ? "production" : "staging";

export {
	API_CONFIG,
	getUrl,
	firstLevelBreadcrumbs,
	userStatusDropdownOptions,
	pageOptions,
	PER_PAGE,
	ENVIRONMENT,
};
