import actionTypes from 'store/action-types';
import { SubscribersState, Action } from 'shared/interface';

const initialState: SubscribersState = {
	subscribers: [],
	frontendUrl: '',
	lastSync: { lastSyncedAt: '' },
	pagination: {
		total: 1,
		perPage: 15,
		page: 1,
		lastPage: 1
	}
};

const reducer = (state: SubscribersState = initialState, action: Action): SubscribersState => {
	switch (action.type) {
		case actionTypes.FETCH_SUBSCRIBERS_INIT:
			return {
				...state,
				subscribers: [],
				pagination: {
					...state.pagination,
					perPage: action.payload.query.perPage,
					page: action.payload.query.page
				}
			};
		// case actionTypes.SYNC_CONTACT_INIT:
		// 	return {
		// 		...state,
		// 		subscribers: [],
		// 	};
		case actionTypes.FETCH_SUBSCRIBERS_SUCCESS:
			return {
				...state,
				subscribers: action.payload.allSubscriber,
				frontendUrl: action.payload.frontendUrl,
				pagination: {
					...state.pagination,
					total: action.payload.countSubscriber,
					lastPage: Math.ceil(action.payload.countSubscriber / state.pagination.perPage) || 1
				},
				lastSync: action.payload.lastSync
			};
		// case actionTypes.DELETE_SUBSCRIBER_SUCCESS:
		// 	return {
		// 		...state,
		// 		subscribers: action.payload.subscribers,
		// 		pagination: {
		// 			...state.pagination,
		// 			total: action.payload.total
		// 		}
		// 	};
		default:
			return state;
	}
};
export default reducer;
