import React, { MouseEventHandler, CSSProperties } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

interface ButtonProps {
	btnType?: 'default' | 'primary' | 'warning' | 'danger' | 'info',
	loading?: boolean,
	disabled?: boolean,
	type?: 'submit' | 'button' | 'reset',
	className?: string,
	onClick?: MouseEventHandler<any>;
	abbr?: string;
	title?: string;
	style?: CSSProperties;
	link?: string;
	href?: string;
}
const Button: React.FC<ButtonProps> = (props) => {
	const onClick: MouseEventHandler<any> = (e) => !props.loading && props.onClick && props.onClick(e);
	let button = (
		<button
			title={props.abbr || ''}
			type={props.type}
			className={classnames('btn', `btn-${props.btnType || 'default'}`, 'text-capitalize', props.className)}
			disabled={props.loading || props.disabled}
			style={props.style || {}}
			onClick={!props.disabled && !props.loading ? onClick : () => { }}
		>
			{
				props.loading ?
					<i className='fa fa-spin fa-spinner' />
					:
					typeof props.children === 'string' ? props.children : props.children
			}
		</button>
	);
	if (props.link) {
		button = (
			<Link to={props.link}>{button}</Link>
		);
	}
	if (props.href) {
		button = (
			<a href={props.href} target='_blank' rel='noopener noreferrer'>{button}</a>
		);
	}
	if (props.abbr) {
		return (
			<abbr title={props.abbr || ''} className='custom-abbr'>
				{button}
			</abbr>
		);
	}
	return button;
};

export default Button;
