import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { SideBarMenu } from "features/login/interface/login.interface";

interface Props extends RouteComponentProps {
	renderSideNav: () => void;
	menu: SideBarMenu;
}

const getPathElements = (path: string) => {
	return path.split("/").filter((str) => str !== "");
};

const getActiveClass = (
	currentPathStr: string,
	actualPathStr: string
): string => {
	// for base path, it's compulsary to have both path as `/`, in order to show `active` status
	if (actualPathStr === "/") {
		if (currentPathStr === actualPathStr) {
			return "active";
		}
		return "bl--transparent";
	}
	const actualPath = getPathElements(actualPathStr);
	const currentPath = getPathElements(currentPathStr);
	// if the first level path matches for actual path, and current path, return `active`
	if (actualPath[0] === currentPath[0]) {
		return "active";
	}
	return "bl--transparent";
};

const icons: { [key: string]: string } = {
	subscribers: "user",
	dashboard: "dashboard",
	"Post Newsletter": "newspaper-o",
	"Make Money": "newspaper-o",
	"Save More": "newspaper-o",
	"Live Smarter": "newspaper-o",
};

const NavItem: React.FC<Props> = (props) => {
	return (
		<li
			className={getActiveClass(
				props.location.pathname,
				props.menu.route
			)}
			onClick={props.renderSideNav}
		>
			<Link to={props.menu.route}>
				<i className={`fa fa-${icons[props.menu.name]}`} />
				<span className="nav-label">{props.menu.name}</span>
			</Link>
		</li>
	);
};

export default withRouter(NavItem);
