const actionTypes = {
	AUTH_INIT: "AUTH_INIT",
	AUTH_SUCCESS: "AUTH_SUCCESS",
	AUTH_FAIL: "AUTH_FAIL",
	SYNC_CONTACT_INIT: "SYNC_CONTACT_INIT",
	SYNC_CONTACT_SUCCESS: "SYNC_CONTACT_SUCCESS",
	SYNC_CONTACT_FAIL: "SYNC_CONTACT_FAIL",

	GET_PROFILE_INIT: "GET_PROFILE_INIT",
	GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
	GET_PROFILE_FAIL: "GET_PROFILE_FAIL",

	CHANGE_PASSWORD_INIT: "CHANGE_PASSWORD_INIT",
	CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
	CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",

	UPDATE_PROFILE_INIT: "UPDATE_PROFILE_INIT",
	UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
	UPDATE_PROFILE_FAIL: "UPDATE_PROFILE_FAIL",

	FORGET_PASSWORD_INIT: "FORGET_PASSWORD_INIT",
	FORGET_PASSWORD_SUCCESS: "FORGET_PASSWORD_SUCCESS",
	FORGET_PASSWORD_FAIL: "FORGET_PASSWORD_FAIL",

	RESET_PASSWORD_INIT: "RESET_PASSWORD_INIT",
	RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
	RESET_PASSWORD_FAIL: "RESET_PASSWORD_FAIL",

	AUTH_LOGOUT_INIT: "AUTH_LOGOUT_INIT",
	AUTH_LOGOUT_SUCCESS: "AUTH_LOGOUT_SUCCESS",
	AUTH_LOGOUT_FAIL: "AUTH_LOGOUT_FAIL",

	FETCH_SUBSCRIBERS_INIT: "FETCH_SUBSCRIBERS_INIT",
	FETCH_SUBSCRIBERS_SUCCESS: "FETCH_SUBSCRIBERS_SUCCESS",
	FETCH_SUBSCRIBERS_FAIL: "FETCH_SUBSCRIBERS_FAIL",

	FETCH_NEWSLETTER_INIT: "FETCH_NEWSLETTER_INIT",
	FETCH_NEWSLETTER_SUCCESS: "FETCH_NEWSLETTER_SUCCESS",
	FETCH_NEWSLETTER_FAIL: "FETCH_NEWSLETTER_FAIL",

	DELETE_SUBSCRIBER_INIT: "DELETE_SUBSCRIBER_INIT",
	DELETE_SUBSCRIBER_SUCCESS: "DELETE_SUBSCRIBER_SUCCESS",
	DELETE_SUBSCRIBER_FAIL: "DELETE_SUBSCRIBER_FAIL",

	UPLOAD_NEWSLETTERPDF_INIT: "UPLOAD_NEWSLETTERPDF_INIT",
	UPLOAD_NEWSLETTERPDF_SUCCESS: "UPLOAD_NEWSLETTERPDF_SUCCESS",
	UPLOAD_NEWSLETTERPDF_FAIL: "UPLOAD_NEWSLETTERPDF_FAIL",

	NO_ACTION: "NO_ACTION",
	OPEN_MODAL: "OPEN_MODAL",
	CLOSE_MODAL: "CLOSE_MODAL",
	SET_NOTIFICATION: "SET_NOTIFICATION",
	RESET_NOTIFICATION: "RESET_NOTIFICATION",
};

export default actionTypes;
