import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './shared/components/errorBoundary/errorBoundary';
import App from './App';
import store from './store';

const Root: React.FC = props => {
	return (
		<Provider store={store}>
			<ErrorBoundary>
				<BrowserRouter>
					<App {...props} />
				</BrowserRouter>
			</ErrorBoundary>
		</Provider>
	);
};

export default Root;
